<template>
  <div>
    <h1 class="text-primary my-1">Msrp Options Name</h1>
    <b-row>
      <b-col md="6">
        <b-form-group>
          <validation-provider #default="{ errors }" name="Model" rules="required">
            <v-select :options="makeoptions" v-model="selectedmakeOption" :value="makeoptions" label="value" :placeholder="'Choose Make'" :taggable="true" :clearable="true" :loading="dropdownLoading" @input="getRelatedModels"></v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <validation-provider #default="{ errors }" name="Model" rules="required">
            <v-select :options="modeloptions" v-model="selectedmodelOption" :value="modeloptions" label="value" :placeholder="'Choose Model'" :taggable="true" :clearable="true" :loading="dropdownLoading"></v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group class="mb-40">
          <b-input-group>
            <b-form-input v-model.lazy="newitem" type="text" placeholder="Add Item"></b-form-input>
            <b-input-group-append>
              <b-button @click="addItem()" variant="success">Add +</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group class="mb-40">
          <b-input-group>
            <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Search"></b-form-input>
            <b-input-group-append>
              <b-button @click="filter = ''" variant="primary">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-table :items="items" :small="true" responsive style="padding-bottom:30px" class="position-relative " head-variant="dark" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" :fields="column" striped hover :current-page="currentPage" :per-page="perPage">
      <template v-slot:cell()="{ value, item, field: { key } }">
        <template v-if="edit != item.id">{{ value }}</template>
        <b-form-input v-else v-model="item[key]" />
      </template>
      <template v-slot:cell(actions)="data">
        <b-button size="sm" @click="onEdit(data.item.id, data.item.option)" class="mr-1" variant="primary">
          {{ edit === data.item.id ? 'Save' : 'Edit' }}
        </b-button>
        <b-button size="sm" @click="removeItem($event, data.item.id)" class="mr-1" variant="danger">
          Del
        </b-button>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start" style="padding-left: 0;"> Total entries : {{ totalRows }} </b-col>

        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end" style="padding-right: 5px;">
          <b-pagination first-number last-number :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import axiosIns from '@/libs/axios';
import { BFormGroup, BTable, BRow, BCol, BFormSelect, BPagination } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import vSelect from 'vue-select';
export default {
  components: {
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    required,
  },
  created() {
    this.getItems();
    this.makeoptionsData();
  },
  data() {
    return {
      selectedmakeOption: '',
      selectedmodelOption: '',
      dropdownLoading: false,
      makeoptions: [],
      modeloptions: [],
      edit: null,
      newitem: '',
      filter: null,
      filterOn: [],
      perPage: 10,
      items: [],
      currentPage: 1,
      pageOptions: [5, 10, 15, 20],
      column: [
        { key: 'option', sortable: true, label: 'Name' },
        { key: 'actions', label: 'Actions', thClass: 'text-right', tdClass: 'text-right pr-0' },
      ],
    };
  },
  computed: {
    totalRows() {
      return this.items.length;
    },
  },
  methods: {
    getRelatedModels() {
      if (this.selectedmakeOption.id == null) {
        return;
      }
      let selectedmakeOptionId = this.selectedmakeOption.id;
      axiosIns.get(`model/${selectedmakeOptionId}`).then((response) => {
        this.modeloptions = response.data || [];
      });
    },
    makeoptionsData() {
      axiosIns.get('/make').then((response) => {
        this.makeoptions = response.data || [];
      });
    },
    onEdit(id, option) {
      console.log(option);
      this.edit = this.edit !== id ? id : this.editItem(id, option);
    },
    getItems() {
      axiosIns
        .get('msrpoption')
        .then((res) => {
          this.items = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addItem() {
      if (this.newitem == '') {
        return;
      }
      if (this.selectedmakeOption.id == undefined) {
        return;
      }
      if (this.selectedmodelOption.id == undefined) {
        return;
      }

      axiosIns
        .post('msrpoption', {
          option: this.newitem,
          makeId: this.selectedmakeOption.id,
          modelId: this.selectedmodelOption.id,
        })
        .then((res) => {
          if (res.status == 201) {
            this.newitem = '';
            this.getItems();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeItem(event, id) {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          event.preventDefault();
          axiosIns
            .delete(`msrpoption/${id}`)
            .then((res) => {
              if (res.status == 200) {
                this.getItems();
              }
            })
            .catch((error) => {
              console.log('error delete ', error);
            });
        }
      });
    },
    editItem(id, option) {
      if (option == '') {
        return;
      }
      axiosIns
        .put(`msrpoption/${id}`, {
          option: option,
        })
        .then((res) => {
          console.log(this.item.option);
          if (res.status == 200) {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {},
};
</script>
