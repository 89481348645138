<template>
  <div>
    <b-overlay rounded="sm" no-fade>
      <form-wizard color="#3178cf" ref="wizard" scope="props" :title="null" :subtitle="null" shape="square" layout="vertical" back-button-text="Previous" class="vertical-steps steps-transparent">
        <tab-content title="Exterior Colour" icon="feather icon-file-text" lazy="ture">
          <ext-colour></ext-colour>
        </tab-content>

        <tab-content title="Interior Colour" icon="feather icon-file-text" lazy="ture">
          <int-colour></int-colour>
        </tab-content>
        <tab-content title="Model Year" icon="feather icon-file-text" lazy="ture">
          <model-year></model-year>
        </tab-content>

        <tab-content title="Make" icon="feather icon-file-text" lazy="ture">
          <make></make>
        </tab-content>
        <tab-content title="Body Type" icon="feather icon-file-text" lazy="ture">
          <body-type></body-type>
        </tab-content>
        <tab-content title="Fuel Type" icon="feather icon-file-text" lazy="ture">
          <fuel-type></fuel-type>
        </tab-content>
        <tab-content title="Model" icon="feather icon-file-text" lazy="ture">
          <model></model>
        </tab-content>
        <tab-content title="Drivetrain" icon="feather icon-file-text" lazy="ture">
          <drivetrain></drivetrain>
        </tab-content>
        <tab-content title="Trim" icon="feather icon-file-text" lazy="ture">
          <trim></trim>
        </tab-content>
        <tab-content title="Transmission" icon="feather icon-file-text" lazy="ture">
          <transmission></transmission>
        </tab-content>
        <tab-content title="Original Market " icon="feather icon-file-text" lazy="ture">
          <original-market></original-market>
        </tab-content>
        <tab-content title="Base MSRP Currency " icon="feather icon-file-text" lazy="ture">
          <msrp-currency></msrp-currency>
        </tab-content>
        <tab-content title="Msrp Options Name " icon="feather icon-file-text" lazy="ture">
          <msrp-option></msrp-option>
        </tab-content>
        <tab-content title="Msrp Options Price " icon="feather icon-file-text" lazy="ture">
          <msrp-price></msrp-price>
        </tab-content>
      </form-wizard>
    </b-overlay>
  </div>
</template>

<script>
import { BContainer, BOverlay, BRow, BCol } from 'bootstrap-vue';
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import ExtColour from './ExtColour';
import IntColour from './IntColour';
import ModelYear from './ModelYear';
import Make from './Make';
import BodyType from './BodyType';
import FuelType from './FuelType';
import Model from './Model';
import Drivetrain from './Drivetrain';
import Trim from './Trim';
import Transmission from './Transmission';
import OriginalMarket from './OriginalMarket';
import MsrpCurrency from './MsrpCurrency';
import MsrpOption from './MsrpOption';
import MsrpPrice from './MsrpPrice';
import ImportExcel from './ImportExcel.vue';
export default {
  components: {
    BContainer,
    BOverlay,
    BRow,
    BCol,
    FormWizard,
    TabContent,
    WizardButton,
    ExtColour,
    IntColour,
    ModelYear,
    Make,
    BodyType,
    FuelType,
    Model,
    Drivetrain,
    Trim,
    Transmission,
    OriginalMarket,
    MsrpCurrency,
    MsrpOption,
    MsrpPrice,
    ImportExcel,
  },
  directives: {},

  setup() {},

  watch: {},

  mounted() {
    var wizard = this.$refs.wizard;
    wizard.activateAll();
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.add-new-dropdown {
  padding: $options-padding-y $options-padding-x;
  color: $success;

  &:hover {
    background-color: rgba($success, 0.12);
    cursor: pointer;
  }
}
.c-but {
  height: 38px;
  width: 100px;
  border-radius: 0 5px 5px 0;
}
.d-but {
  border: none;
  border-radius: 3px;
}
</style>
