<template>
  <div>
    <h1 class="text-primary my-1">Import Excell</h1>
    <b-row>
      <b-col cols="6">
        <b-form-group class="mb-40">
          <div>
            <b-form-file accept=".xls, .xlsx" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." @change="importExcelFile" ref="file-input" />
          </div>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BFormFile } from 'bootstrap-vue';
import axiosIns from '@/libs/axios';

export default {
  components: {
    BFormFile,
  },
  data() {
    return {
      excelJson: {},
    };
  },
  methods: {
    importExcelFile(e) {
      var files = e.target.files,
        f = files[0];
      var reader = new FileReader();
      const self = this;
      reader.onload = function(e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: 'array' });
        let sheetName = workbook.SheetNames[0];
        /* DO SOMETHING WITH workbook HERE */

        let worksheet = workbook.Sheets[sheetName];

        this.excelJson = XLSX.utils.sheet_to_json(worksheet, { defval: null });

        let controlExcelColumn = 0;
        let matchRegex = /(vinNo|condition|mileageUnit|location|extColour)/g;

        if (this.excelJson.length > 0 && this.excelJson != {}) {
          for (let x in this.excelJson[0]) {
            if (x.match(matchRegex)) {
              controlExcelColumn += 1;
            }
          }
          if (controlExcelColumn === 5) {
            axiosIns
              .post('import-excel', this.excelJson)
              .then((res) => {
                self.$refs['file-input'].reset();
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            self.$swal({
              title: 'Error!',
              text: 'Oops, This excel table headers not correct. Make sure the headers are correct',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            });
          }
        }
      };
      reader.readAsArrayBuffer(f);
    },
  },
};
</script>
